import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Image, Text, Flex, Box } from 'rebass/styled-components';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import FontAwesome from 'react-fontawesome';
import SocialLink from '../components/SocialLink';
import Section from '../components/Section';
import Hide from '../components/Hide';
import { CardContainer, Card } from '../components/Card';
import Triangle from '../components/Triangle';
import ImageSubtitle from '../components/ImageSubtitle';
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Background = () => (
  <div>
    <Triangle
      color="backgroundLight"
      height={['80vh', '80vh']}
      width={['100vw', '100vw']}
      invertX
    />

    <Triangle
      color="secondaryLight"
      height={['50vh', '20vh']}
      width={['50vw', '49vw']}
      invertX
    />

    <Triangle
      color="primaryDark"
      height={['25vh', '40vh']}
      width={['75vw', '60vw']}
      invertX
      invertY
    />

    <Triangle
      color="terciaryDark"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
      invertY
    />
  </div>
);

const CARD_HEIGHT = '300px';
const IMAGE_SIZE = '200px';

const MEDIA_QUERY_SMALL = '@media (max-width: 400px)';

const Title = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: table;
  border-bottom: ${props => props.theme.colors.primary} 5px solid;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  width: calc(100% - ${IMAGE_SIZE});

  ${MEDIA_QUERY_SMALL} {
    width: calc(100% - (${IMAGE_SIZE} / 2));
  }
`;

const WorkContainer = styled.div`
  margin: auto;
  width: ${IMAGE_SIZE};

  ${MEDIA_QUERY_SMALL} {
    width: calc(${IMAGE_SIZE} / 2);
  }
`;

const WorkImage = styled(Image)`
  object-fit: contain;
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
  padding: 40px;
  margin-top: calc(${CARD_HEIGHT} / 2 - ${IMAGE_SIZE} / 2);

  ${MEDIA_QUERY_SMALL} {
    height: calc(${IMAGE_SIZE} / 2);
    width: calc(${IMAGE_SIZE} / 2);
    padding: 10px;
  }
`;

const WorkTag = styled.div`
  position: relative;
  height: ${CARD_HEIGHT};
  top: calc(
    -${CARD_HEIGHT} / 2 - ${IMAGE_SIZE} / 2 - 3.5px
  ); /*don't know why I have to add 3.5px here ... */

  ${MEDIA_QUERY_SMALL} {
    top: -${CARD_HEIGHT} / 2 - ${IMAGE_SIZE} / 2 - 3.5px;
  }
`;

const Work = ({
  company,
  jobTitle,
  periodStart,
  periodEnd,
  stillWorkingThere,
  url,
  logo,
  description,
}) => (
    <Card p={0}>
      <Flex style={{ height: CARD_HEIGHT }}>
        <TextContainer>
          <span>
            <Title my={2} pb={1}>
              {jobTitle}
            </Title>
          </span>
          <Text width={[1]} style={{overflow: 'auto'}}>
          {renderRichText(description)}
          </Text>
        </TextContainer>

        <WorkContainer>
        <WorkImage src={logo?.image?.src ?? logo?.file?.url} alt={logo?.title} />
          <WorkTag>
            <Flex
              style={{
                float: 'right',
              }}
            >
              <Box mx={1} fontSize={5}>
                {url &&
                  <SocialLink
                    name="Check website"
                    fontAwesomeIcon="globe"
                    url={url}
                    color="primaryLight"
                    hoverColor="primary"
                  />
                }
              </Box>
            </Flex>
          <ImageSubtitle fix bg={stillWorkingThere ? "primaryLight" : "terciary"} color="white" y="bottom" x="right">
              {company}
            </ImageSubtitle>
            <Hide query={MEDIA_QUERY_SMALL}>
              <ImageSubtitle fix color="white" bg="backgroundDark">{periodStart} - {periodEnd || "now"}</ImageSubtitle>
            </Hide>
          </WorkTag>
        </WorkContainer>
      </Flex>
    </Card>
  );

Work.propTypes = {
  company: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  periodStart: PropTypes.string.isRequired,
  periodEnd: PropTypes.string,
  location: PropTypes.object,
  stillWorkingThere: PropTypes.bool.isRequired,
  url: PropTypes.string,
  //customers: PropTypes.oneOf(Work),
  description: PropTypes.any.isRequired,
  logo: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
    }),
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

// customers {
//   ... on ContentfulProject {
//     id
//     name
//     description
//     projectUrl
//     repositoryUrl
//     publishedDate(formatString: "YYYY")
//     role
//     type
//     logo {
//       title
//       image: resize(width: 200, quality: 100) {
//         src
//       }
//     }
//   }
//   ... on ContentfulWork {
//     id
//     company
//     jobTitle
//     periodStart(formatString: "MM/YYYY")
//     periodEnd(formatString: "MM/YYYY")
//     location {
//       lat
//       lon
//     }
//     stillWorkingThere
//     url
//     logo {
//       title
//       image: resize(width: 200, quality: 100) {
//         src
//       }
//     }
//     workDescription: description {
//       json: raw
//     }
//   }
// }

const Works = () => (
  <Section.Container id="work" Background={Background}>
    <Section.Header name="Work" icon="💻" Box="notebook" />
    <StaticQuery
      query={graphql`
        query WorkQuery {
          allContentfulWork(sort: {order: [DESC,DESC], fields: [stillWorkingThere,periodStart]}) {
            nodes {
              id
              company
              jobTitle
              periodStart(formatString: "MM/YYYY")
              periodEnd(formatString: "MM/YYYY")
              location {
                lat
                lon
              }
              stillWorkingThere
              url
              logo {
                title
                image: resize(width: 200, quality: 100) {
                  src
                }
                file {
                  url
                }
              }
              description {
                raw
              }
            }
          }
        }
      `}
      render={({ allContentfulWork }) => (
        <CardContainer minWidth="500px">
          {allContentfulWork.nodes.map((p, i) => (
            <Work key={p.id} {...p} />
          ))}
        </CardContainer>
      )}
    />
  </Section.Container>
);

export default Works;
